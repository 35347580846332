import { lazy } from '@loadable/component';
import CenteredLayout from './components/layouts/CenteredLayout';
import DashboardLayout from './components/layouts/DashboardLayout';

// lazily load the app which is the actual app
// the next component is only about initializing main context and tools for the app to run
const LazyApp = lazy(() => {
    // we preload components
    CenteredLayout.preload();
    DashboardLayout.preload();

    return import('./App');
});

export default LazyApp;
